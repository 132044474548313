import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <g id="Group_1" data-name="Group 1" transform="translate(-153 -139)">
      <g id="Path_2" data-name="Path 2" transform="translate(153 139)">
        <path
          d="M 95 97 L 5 97 C 3.897200107574463 97 3 96.10279846191406 3 95 L 3 5 C 3 3.897200107574463 3.897200107574463 3 5 3 L 95 3 C 96.10279846191406 3 97 3.897200107574463 97 5 L 97 95 C 97 96.10279846191406 96.10279846191406 97 95 97 Z"
          stroke="currentColor"
        />
        <path
          d="M 6 6 L 6 94 L 94 94 L 94 6 L 6 6 M 5 0 L 95 0 C 97.76142120361328 0 100 2.238578796386719 100 5 L 100 95 C 100 97.76142120361328 97.76142120361328 100 95 100 L 5 100 C 2.238578796386719 100 0 97.76142120361328 0 95 L 0 5 C 0 2.238578796386719 2.238578796386719 0 5 0 Z"
          stroke="currentColor"
          fill="currentColor"
        />
      </g>
      <path
        id="Path_1"
        data-name="Path 1"
        d="M11.31.464A15.194,15.194,0,0,1,4.524-1.073,12.9,12.9,0,0,1-.522-5.394L2.842-9.338Q6.206-4.524,11.31-4.524A6.423,6.423,0,0,0,16.5-6.612Q18.27-8.7,18.27-12.76V-35.554H3.6V-40.6H24.012v27.55q0,6.728-3.219,10.121T11.31.464ZM38.628-40.6h5.8V-5.046H66.41V0H38.628Z"
        transform="translate(170 210)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default IconLogo;
