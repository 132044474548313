import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';

const StyledContributionsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .archive-link {
    font-family: var(--font-mono);
    font-size: var(--fz-sm);
    &:after {
      bottom: 0.1em;
    }
  }

  .contributions-grid {
    ${({ theme }) => theme.mixins.resetList};
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;
    margin-top: 50px;
    width: 100%;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
`;

const StyledContribution = styled.li`
  position: relative;
  cursor: default;
  transition: var(--transition);

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .project-inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .contribution-inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: stretch;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    transition: var(--transition);
  }

  .contribution-top {
    ${({ theme }) => theme.mixins.flexBetween};
    margin-bottom: 35px;

    .folder {
      color: var(--green);
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .contribution-links {
      display: flex;
      align-items: center;
      color: var(--light-slate);

      span {
        display: inline-block;
        &.stats-item {
          margin-left: 0.8rem;
        }
        &.stats-item-icon {
          margin-right: 0.2rem;
        }
        &.stats-item-text {
          font-size: var(--fz-md);
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .contribution-title {
    margin: 0 0 10px;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);

    a {
      position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .contribution-description {
    color: var(--light-slate);
    font-size: 17px;

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }
  }
  .footer {
    display: flex;
    margin: 20px 0 0 0;
    justify-content: space-between;

    .contribution-tech-list {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      flex-wrap: wrap;
      padding: 0;

      list-style: none;

      li {
        font-family: var(--font-mono);
        font-size: var(--fz-xxs);
        line-height: 1.75;

        &:not(:last-of-type) {
          margin-right: 15px;
        }
      }
    }

    a {
      ${({ theme }) => theme.mixins.flexCenter};

      &.external {
        svg {
          width: 22px;
          height: 22px;
          margin-top: -4px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      contributions: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/contributions/" }
          frontmatter: { showInProjects: { ne: false } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              tech
              github
              external
              watch
              fork
              star
            }
            html
          }
        }
      }
    }
  `);

  const revealTitle = useRef(null);
  const revealArchiveLink = useRef(null);
  const revealContributions = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealContributions.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 6;
  const contributions = data.contributions.edges.filter(({ node }) => node);

  const projectInner = node => {
    const { frontmatter, html } = node;
    const { external, title, tech, watch, fork, star } = frontmatter;

    return (
      <div className="contribution-inner">
        <header>
          <div className="contribution-top">
            <div className="folder">
              <Icon name="GitHubLarge" />
            </div>
            <div className="contribution-links">
              {watch && (
                <span
                  className="stats-item"
                  aria-label="GitHub Watch"
                  target="_blank"
                  rel="noreferrer">
                  <span className="stats-item-icon">
                    <Icon name="Watch" />
                  </span>
                  <span className="stats-item-text">{watch}</span>
                </span>
              )}
              {fork && (
                <span
                  className="stats-item"
                  aria-label="GitHub Fork"
                  target="_blank"
                  rel="noreferrer">
                  <span className="stats-item-icon">
                    <Icon name="Fork" />
                  </span>
                  <span className="stats-item-text">{fork}</span>
                </span>
              )}
              {star && (
                <span
                  className="stats-item"
                  aria-label="GitHub Star"
                  target="_blank"
                  rel="noreferrer">
                  <span className="stats-item-icon">
                    <Icon name="Star" />
                  </span>
                  <span className="stats-item-text">{star}</span>
                </span>
              )}
            </div>
          </div>

          <h3 className="contribution-title">
            <a href={external} target="_blank" rel="noreferrer">
              {title}
            </a>
          </h3>

          <div className="contribution-description" dangerouslySetInnerHTML={{ __html: html }} />
        </header>

        <footer className="footer">
          {tech && (
            <ul className="contribution-tech-list">
              {tech.map((tech, i) => (
                <li key={i}>{tech}</li>
              ))}
            </ul>
          )}
          {external && (
            <a
              href={external}
              aria-label="External Link"
              className="external"
              target="_blank"
              rel="noreferrer">
              <Icon name="External" />
            </a>
          )}
        </footer>
      </div>
    );
  };

  return (
    <StyledContributionsSection>
      <h2 ref={revealTitle}>Open Source Contributions</h2>

      <ul className="contributions-grid">
        {prefersReducedMotion ? (
          <>
            {contributions &&
              contributions.map(({ node }, i) => (
                <StyledContribution key={i}>{projectInner(node)}</StyledContribution>
              ))}
          </>
        ) : (
          <TransitionGroup component={null}>
            {contributions &&
              contributions.map(({ node }, i) => (
                <CSSTransition
                  key={i}
                  classNames="fadeup"
                  timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                  exit={false}>
                  <StyledContribution
                    key={i}
                    ref={el => (revealContributions.current[i] = el)}
                    style={{
                      transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                    }}>
                    {projectInner(node)}
                  </StyledContribution>
                </CSSTransition>
              ))}
          </TransitionGroup>
        )}
      </ul>
    </StyledContributionsSection>
  );
};

export default Projects;
