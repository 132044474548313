import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledJobsSection = styled.section`
  max-width: 700px;

  .inner {
    display: flex;

    @media (max-width: 600px) {
      display: block;
    }

    // Prevent container from jumping
    @media (min-width: 700px) {
      min-height: 340px;
    }
  }
`;

const StyledTabPanels = styled.div`
  position: relative;
  width: 100%;
  padding-left: 20px;
  border-left: 1px solid var(--light-slate);
  margin-left: 1rem;
`;

const StyledTabPanel = styled.div`
  width: 100%;
  height: auto;
  padding: 0 20px 20px 20px;
  margin-top: -5px;
  ul {
    ${({ theme }) => theme.mixins.fancyList};
  }

  .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    position: relative;
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .dot {
      position: absolute;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: var(--green);
      left: -47px;
    }

    h3 {
      margin-bottom: 0;
      font-size: var(--fz-xxl);
      font-weight: 500;

      .company {
        color: var(--green);
      }
    }

    .range {
      color: var(--light-slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      @media (max-width: 600px) {
        margin-top: 0.5rem;
      }
    }
  }
`;

const Jobs = () => {
  const data = useStaticQuery(graphql`
    query {
      jobs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/jobs/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              location
              range
              url
            }
            html
          }
        }
      }
    }
  `);

  const jobsData = data.jobs.edges;

  const [tabFocus, setTabFocus] = useState(null);
  const tabs = useRef([]);
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const focusTab = () => {
    if (tabs.current[tabFocus]) {
      tabs.current[tabFocus].focus();
      return;
    }
    // If we're at the end, go to the start
    if (tabFocus >= tabs.current.length) {
      setTabFocus(0);
    }
    // If we're at the start, move to the end
    if (tabFocus < 0) {
      setTabFocus(tabs.current.length - 1);
    }
  };

  // Only re-run the effect if tabFocus changes
  useEffect(() => focusTab(), [tabFocus]);

  return (
    <StyledJobsSection id="jobs" ref={revealContainer}>
      <h2 className="numbered-heading">Where I’ve Worked</h2>

      <div className="inner">
        <StyledTabPanels>
          {jobsData &&
            jobsData.map(({ node }, i) => {
              const { frontmatter, html } = node;
              const { title, url, company, range } = frontmatter;

              return (
                <StyledTabPanel key={i} id={`panel-${i}`} role="tabpanel">
                  <div className="heading">
                    <div className="dot" />
                    <h3>
                      <span>{title}</span>
                      <span className="company">
                        &nbsp;@&nbsp;
                        <a href={url} className="inline-link">
                          {company}
                        </a>
                      </span>
                    </h3>
                    <p className="range">{range}</p>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </StyledTabPanel>
              );
            })}
        </StyledTabPanels>
      </div>
    </StyledJobsSection>
  );
};

export default Jobs;
