import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledEducationSection = styled.section`
  max-width: 700px;

  .inner {
    display: flex;

    @media (max-width: 600px) {
      display: block;
    }

    // Prevent container from jumping
    @media (min-width: 700px) {
      /* min-height: 340px; */
    }
  }
`;

const StyledTabPanels = styled.div`
  position: relative;
  width: 100%;
  padding-right: 20px;
  border-right: 1px solid var(--light-slate);
  margin-right: 1rem;
`;

const StyledTabPanel = styled.div`
  width: 100%;
  height: auto;
  padding: 0 20px 20px 20px;
  margin-top: -5px;

  &:last-of-type {
    padding-bottom: 0;
  }

  ul {
    ${({ theme }) => theme.mixins.fancyList};
  }

  .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    position: relative;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .dot {
      position: absolute;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: var(--green);
      right: -47px;
    }

    h3 {
      margin-bottom: 0.5rem;
      font-size: var(--fz-xxl);
      font-weight: 500;

      .company {
        color: var(--green);
      }
    }

    .range {
      color: var(--light-slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      flex-shrink: 0;
      @media (max-width: 600px) {
        margin-top: 0.5rem;
      }
    }
  }
`;

const Studies = () => {
  const data = useStaticQuery(graphql`
    query {
      studies: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/education/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              school
              location
              range
              url
              order
            }
            html
          }
        }
      }
    }
  `);

  const studies = data.studies.edges;
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledEducationSection id="education" ref={revealContainer}>
      <h2 className="numbered-heading">Where I’ve Studied</h2>

      <div className="inner">
        <StyledTabPanels>
          {studies &&
            studies
              .sort((a, b) => {
                const { order: orderA } = a.node.frontmatter;
                const { order: orderB } = b.node.frontmatter;
                return orderA - orderB;
              })
              .map(({ node }, i) => {
                const { frontmatter, html } = node;
                const { title, url, school, range, location } = frontmatter;

                return (
                  <StyledTabPanel key={i} id={`panel-${i}`} role="tabpanel">
                    <div className="heading">
                      <div className="dot" />
                      <h3>
                        <span>{title}</span>
                        <span className="company" target="_blank" rel="noreferrer">
                          &nbsp;@&nbsp;
                          <a href={url} className="inline-link">
                            {school}&nbsp;in&nbsp;{location}
                          </a>
                        </span>
                      </h3>
                      <p className="range">{range}</p>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </StyledTabPanel>
                );
              })}
        </StyledTabPanels>
      </div>
    </StyledEducationSection>
  );
};

export default Studies;
