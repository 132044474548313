import React from 'react';

const IconLoader = () => (
  // <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  //   <title>Loader Logo</title>
  //   <g>
  //     <g id="B" transform="translate(11.000000, 5.000000)">
  //       <path
  //         d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         fill="currentColor"
  //       />
  //     </g>
  //     <path
  //       stroke="currentColor"
  //       strokeWidth="5"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       d="M 50, 5
  //                 L 11, 27
  //                 L 11, 72
  //                 L 50, 95
  //                 L 89, 73
  //                 L 89, 28 z"
  //     />
  //   </g>
  // </svg>

  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g transform="translate(-153 -139)">
      <path
        id="JL"
        transform="translate(170 210)"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        // fill="currentColor"
        d="M11.31.464A15.194,15.194,0,0,1,4.524-1.073,12.9,12.9,0,0,1-.522-5.394L2.842-9.338Q6.206-4.524,11.31-4.524A6.423,6.423,0,0,0,16.5-6.612Q18.27-8.7,18.27-12.76V-35.554H3.6V-40.6H24.012v27.55q0,6.728-3.219,10.121T11.31.464ZM38.628-40.6h5.8V-5.046H66.41V0H38.628Z"
      />
      <g transform="translate(153 139)">
        <path
          d="M 95 97 L 5 97 C 3.897200107574463 97 3 96.10279846191406 3 95 L 3 5 C 3 3.897200107574463 3.897200107574463 3 5 3 L 95 3 C 96.10279846191406 3 97 3.897200107574463 97 5 L 97 95 C 97 96.10279846191406 96.10279846191406 97 95 97 Z"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default IconLoader;
